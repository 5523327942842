import React from "react"

import MainLayout from "../layouts/main"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="404: Not found" />
    <section className="error">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </MainLayout>
)

export default NotFoundPage
