import React from 'react';
import { Alert } from 'antd';

function isIE() {
	// IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

const IeMessage = () => {
	if(isIE()) {
		return (
			<div className="unsupported">
				<p>Je gebruikt een verouderde browser waardoor je niet optimaal van onze muziek kunt genieten. Update je browser aub.</p>
			</div>
		);
	}
	return '';
}

export default IeMessage
